<template>
  <v-col class="ma-3">
    <v-form
      ref="form"
      v-model="isValid"
      @submit.prevent="handleLogin()"
    >
      <h1 class="text-h1 white--text mb-12">
        {{ $t('authorisation.login.title') }}
      </h1>
      <v-alert
        :type="alertType"
        :value="!!alertMessage.length"
        class="mb-10"
        transition="fade-transition"
      >
        {{ alertMessage }}
      </v-alert>
      <k-field-group language-prefix="authorisation.fields">
        <KTextField
          v-model="form.email"
          autocomplete="username"
          autofocus
          field="email"
          tabindex="1"
        />
        <KTextField
          v-model="form.password"
          :append-icon="showPassword ? '$invisible' : '$visible'"
          :type="showPassword ? 'text' : 'password'"
          autocomplete="current-password"
          field="password"
          tabindex="2"
          @click:append="showPassword = !showPassword"
        />
      </k-field-group>
      <v-row
        class="no-gutters my-6"
        justify="end"
      >
        <VBtn
          :to="{ name: 'password.forgotten' }"
          dark
          tabindex="4"
          text
          class="mr-3"
          v-text="$t('authorisation.actions.passwordForgotten')"
        />
        <VBtn
          :loading="isLoading"
          light
          tabindex="3"
          type="submit"
          depressed
          tile
          v-text="$t('authorisation.actions.login')"
        />
      </v-row>
    </v-form>
    <v-overlay
      v-model="isRedirecting"
      class="text-center"
    >
      <VProgressCircular
        indeterminate
        size="64"
      />
      <div
        class="mt-5"
        v-text="$t('authorisation.login.successMessage')"
      />
    </v-overlay>
  </v-col>
</template>

<script>
import KFieldGroup from '@/components/crud/fields/KFieldGroup.vue';
import KTextField from '@/components/crud/fields/KTextField.vue';
import { mapGetters } from 'vuex';
import LoginRequest from '@/modules/authorisation/api/login.js';
import { getRateLimitMinutes } from '@/application/api/util/response.js';
import { getOrganisationFromUrl } from '@/application/util/url.js';

export default {
  name: 'Login',
  components: {
    KFieldGroup,
    KTextField,
  },
  props: {
    invitationAcceptRedirect: {
      type: Boolean,
      default: false,
    },
    passwordResetRedirect: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      alertType: 'error',
      alertMessage: '',
      isLoading: false,
      showPassword: false,
      isValid: false,
      form: {
        email: '',
        password: '',
      },
      isRedirecting: false,
    };
  },
  computed: {
    ...mapGetters({
      findError: 'error/find',
    }),
  },
  created() {
    if (this.invitationAcceptRedirect) {
      this.alertType = 'success';
      this.alertMessage = this.$t('authorisation.invitationAccept.successMessage');
    }
    if (this.passwordResetRedirect) {
      this.alertType = 'success';
      this.alertMessage = this.$t('authorisation.passwordReset.successMessage');
    }
  },
  methods: {
    handleLogin() {
      this.isLoading = true;
      LoginRequest(this.form.email, this.form.password)
        .then((res) => {
          this.isRedirecting = true;
          this.redirectToAuthDispense(res.data.data.token);
          this.alertMessage = null;
        })
        .catch((err) => {
          this.alertType = 'error';

          const { response } = err;
          const { status } = response;

          if (status === 429) {
            this.alertMessage = this.$t('errors.429', { minutes: getRateLimitMinutes(response) });
          } else {
            this.alertMessage = this.findError('email');
          }
          this.$refs.form.validate();
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    redirectToAuthDispense(token) {
      const form = document.createElement('form');

      form.method = 'POST';
      form.action = `${process.env.VUE_APP_ROOT_API}/auth/dispense`;

      const redirectUriElement = document.createElement('input');
      redirectUriElement.name = 'redirect_uri';
      redirectUriElement.value = 'dashboard';
      form.appendChild(redirectUriElement);

      const emailElement = document.createElement('input');
      emailElement.name = 'email';
      emailElement.value = this.form.email;
      form.appendChild(emailElement);

      const tokenElement = document.createElement('input');
      tokenElement.name = 'token';
      tokenElement.value = token;
      form.appendChild(tokenElement);

      const organisationElement = document.createElement('input');
      organisationElement.name = 'organisation';
      organisationElement.value = getOrganisationFromUrl();
      form.appendChild(organisationElement);

      document.body.appendChild(form);
      form.submit();
    },
  },
};
</script>
