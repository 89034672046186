import { post } from '@/application/api/implementations/app';
import { getOrganisationFromUrl } from '@/application/util/url.js';

export default function (email, password) {
  return post('auth/login', {
    organisation: getOrganisationFromUrl(),
    email,
    password,
  });
}
